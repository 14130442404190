<template>
    <div class="row m-0 p-2 col-12">
        <div class="d-flex align-items-center col-12 p-2 m-0 bg-secondary" align-items-center>
            <i class="fa fa-home" role="button" @click="goHome"></i>
            <template v-for="(dir, i) in stack" :key="dir">
                <i class="fa fa-angle-right px-2"></i>
                <label role="button" class="m-0 p-0" @click="() => goDir(i)">{{dir}}</label>
            </template>
        </div>
        <div class="row m-0 p-0 py-1 col-12">
            <div class="col-10 col-md-8 col-lg-5 col-xl-4 px-0 pr-2">
                <input type="search" name="q" class="form-control mx-0" @submit="getPathWithQ" placeholder="Procurar gifs" v-model="q">
            </div>
            <button class="btn btn-primary" type="button" @click="getPathWithQ">
                <i class="fa fa-search"></i>
            </button>
        </div>

        <div class="row flex-wrap justify-content-evenly m-0 p-2 col-12">
            <div role="button" v-for="({type, name, path}, i) in files" :key="i" @click="() => {(type == 'dir' ? go:selectGif)(path)}" class="p-2 file-container col-4 col-md-3 col-lg-2">
                <!-- <img loading="lazy" :src="type == 'dir' ? folderIcon:getFullPath(path)" class="col-12">  -->
                <v-lazy-image :src="type == 'dir' ? folderIcon:getFullPath(path)" class="col-12" />
                <div class="col-12 small p-0 m-0 text-center text-capitalize">{{name.toLowerCase().replace('gifs ', '').replace('.gif', '')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from '@/utilities/Api'
    import {mapActions, mapMutations} from 'vuex'
    import VLazyImage from 'v-lazy-image'
    import folderIcon from '../assets/icons/folder.svg'

    export default {
        name: 'SelectGifComponent',
        data() {
            return {
                path: 'abc/d/g',
                files: [],
                folderIcon,
                q: '',
                viewport: null
            }
        },
        created() {
            document.title = "Fitnes"
            this.goHome()
        },
        components: {
            VLazyImage
        },
        unmounted() {

        },
        emits: ['selectGif'],
        methods: {
            ...mapMutations(['SET_LOADING']),
            ...mapActions(['get']),
            getFullPath(path) {
                return `${Api.url}images/GIFS/${path}`
            },
            selectGif(gif) {
                // tratar selected
                this.$emit('selectGif', gif)
                console.log(gif)
            },
            go(dir) {
                this.path = dir
                this.getPath(dir)
            },
            goHome() {
                this.go('/')
            },
            goDir(index) {
                let fullPath = ''
                for (let i = 0; i <= index; i++) {
                    fullPath += this.stack[i] + '/'
                }
                
                console.log("Go to dir: ", this.stack[index], "Full: ", fullPath)
                this.go(fullPath)
            },

            async getPathWithQ() {
                if (this.q.length > 0) {
                    await this.getPath(undefined, this.q)
                }
            },
            async getPath(path = '', q = null) {
                this.SET_LOADING(true)
                this.files = []

                try {
                    console.log(q)
                    const {data} = await this.get({url: `files?path=${path}` + (q ? `&q=${q}`:'')})
                    this.files = data.files
                    console.log(data)
                } catch(e) {
                    alert("Ocorreu um erro buscando os arquivos")
                    console.log(e)
                } finally {
                    this.SET_LOADING(false)
                }
            }
        },

        computed: {
            stack() {
                console.log(this.path.split('/').filter(value => value.length > 0))
                return this.path.split('/').filter(value => value.length > 0)
            },
        }
    }
</script>

<style scoped lang="scss">
    .file-container {
        transition: all .3s ease-in-out;
        border-radius: 7px;
        &:hover {
            transform: scale(1.05);
            // background: rgba(200, 200, 200, 0.7);
        }
    }
</style>
